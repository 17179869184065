import React, { useState } from 'react'
import { Loading } from 'components/Main'
import SectionPayment from 'components/section/SectionPayment'

function PagePaymentConfirmation() {
  const [loading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, [process.env.REACT_APP_Loading])

  return <>{loading ? <Loading type="page" /> : <SectionPayment />}</>
}

export default PagePaymentConfirmation
