import React from 'react'
import { Typography, Row, Col, Collapse } from 'antd'
import { PrivacyPolicy } from 'constant/privacy_policy/PrivacyPolicy'
import { SectionWrapper, StyledPanel } from './style'
import Aos from 'aos'

const SectionPrivacyPolicy = () => {
  const { Title, Paragraph } = Typography
  Aos.init({
    duration: 1200,
  })

  return (
    <SectionWrapper>
      <Paragraph>
        <pre
          style={{
            background: 'linear-gradient(90deg, #F96B1B 0%, #F25D27 100%)',
            marginLeft: '11px',
            marginRight: '11px',
          }}
        >
          <Title
            level={4}
            style={{ color: 'var(--white-color)', textAlign: 'center', padding: '8px' }}
          >
            MIGOKU menjunjung tinggi prinsip kemanan data dan informasi dan Kebijakan Privasi ini
            ditetapkan sebagai bentuk komitmen nyata untuk melindungi setiap informasi atau data
            pengguna.
          </Title>
        </pre>
      </Paragraph>
      <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
        <Col span={24}>
          {PrivacyPolicy()?.map((row, index) => {
            return (
              <Collapse
                accordion
                key={index}
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
                style={{
                  borderRadius: '4px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 4px 12px',
                  margin: '10px',
                }}
              >
                <StyledPanel
                  header={row.no + '. ' + row.snk}
                  key={row.no}
                  style={{
                    borderLeft: '4px solid var(--orange-color)',
                    borderRadius: '4px',
                  }}
                >
                  {row.data?.map((element, index) => {
                    return (
                      <div key={index}>
                        <b>
                          {element.no}
                          {element.head}
                        </b>
                        <div
                          style={{ marginLeft: 40 }}
                          dangerouslySetInnerHTML={{ __html: element.children }}
                          key={element.no}
                        />
                      </div>
                    )
                  })}
                </StyledPanel>
              </Collapse>
            )
          })}
        </Col>
      </Row>
    </SectionWrapper>
  )
}
export default SectionPrivacyPolicy
