import React from 'react'
import { SectionWrapper, StyledPanel } from './style'
import { Row, Col, Collapse, Typography } from 'antd'
import { Tnc } from 'constant/tnc/Tnc'
import Aos from 'aos'

const { Link } = Typography
const SectionArticle = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <SectionWrapper data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Row>
        <Col span={24}>
          <div className="text-center">
            <h3>
              Selamat datang di <span>Migoku</span>
            </h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p style={{ marginLeft: 11, marginRight: 11 }}>
            Syarat & ketentuan yang ditetapkan di bawah ini mengatur pemakaian layanan yang
            ditawarkan oleh PT. Widea Dagang Nusantara terkait penggunaan platform MIGOKU, baik
            mobile app maupun website. Pengguna disarankan membaca dengan seksama sebelum
            menggunakan Layanan MIGOKU karena dapat berdampak kepada hak dan kewajiban Pengguna di
            bawah hukum.
          </p>
          <p style={{ marginLeft: 11, marginRight: 11 }}>
            Dengan mendaftar dan/atau menggunakan layanan MIGOKU (sebagaimana didefinisikan di bawah
            ini), maka Anda sebagai Pengguna (sebagaimana didefinisikan di bawah ini) menyatakan
            bahwa Anda telah membaca, mengerti, memahami dan menyetujui untuk terikat dengan
            perjanjian Ketentuan Layanan MIGOKU ini, serta kebijakan mengenai perubahan atau
            perbaikan Ketentuan Layanan di kemudian hari (“Syarat & Ketentuan Layanan MIGOKU”).
          </p>
          <p style={{ marginLeft: 11, marginRight: 11 }}>
            Syarat & ketentuan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah
            perjanjian yang sah antara Pengguna dengan PT. Widea Dagang Nusantara{' '}
            <Link href="/">www.migoku.co.id</Link>. Dengan menggunakan dan mengakses MIGOKU,
            Pengguna berarti menyetujui seluruh isi Syarat & Ketentuan Layanan MIGOKU.
          </p>

          {Tnc()?.map((row) => {
            return (
              <div key={row.no}>
                <Collapse
                  accordion
                  style={{
                    borderRadius: '4px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 4px 12px',
                    margin: '10px',
                  }}
                >
                  <StyledPanel
                    header={row.snk}
                    key={row.no}
                    style={{
                      borderLeft: '4px solid var(--orange-color)',
                      borderRadius: '4px',
                    }}
                  >
                    <ul>
                      {row.data?.map((element) => (
                        <li
                          style={{ marginLeft: 40, listStyleType: 'circle' }}
                          dangerouslySetInnerHTML={{ __html: element }}
                          key={element}
                        />
                      ))}
                    </ul>
                  </StyledPanel>
                </Collapse>
              </div>
            )
          })}
        </Col>
      </Row>
    </SectionWrapper>
  )
}

export default SectionArticle
