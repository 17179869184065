import React, { useState } from 'react'
import { SectionHero, Loading, SectionPrivacyPolicy } from 'components/Main'

const PagePrivacyPolicy = () => {
  const [loading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, [process.env.REACT_APP_Loading])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <>
          <SectionHero option="page_title" page="privacy_policy" />
          <SectionPrivacyPolicy />
        </>
      )}
    </>
  )
}
export default PagePrivacyPolicy
