import React from 'react'
import { Header } from '../containers/Main'

const AppHeader = () => {
  return (
    <>
      <header id="header" className="u-header u-header-left-aligned-nav">
        <div className="u-header__section">
          <Header />
        </div>
      </header>
    </>
  )
}

export default AppHeader
