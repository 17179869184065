import React from 'react'
import { Row, Col } from 'antd'
import { CardWrapper, SectionWrapper } from './style'
import Vision from '../../assets/img/vision.svg'
import Mission from '../../assets/img/mission.svg'
import Aos from 'aos'

const SectionVision = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <SectionWrapper style={{ backgroundColor: 'var(--card-color)' }} data-aos="fade-up">
      <Row>
        <Col lg={24}>
          <Row>
            <Col
              lg={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              sm={6}
              xs={{ span: 24, order: 2 }}
              className="text-content vision"
            >
              <CardWrapper className="card_vision">
                <div className="banner-card__body">
                  <h3>Visi Kami</h3>
                  <p>
                    Menjadi aplikasi yang dipercaya untuk membantu penjualan dan distribusi minyak
                    goreng dengan harga terbaik dan tepat sasaran dengan cara menghubungkan
                    pelanggan ke produsen minyak goreng.
                  </p>
                </div>
              </CardWrapper>
            </Col>
            <Col
              lg={{ span: 12, order: 2 }}
              md={{ span: 12, order: 2 }}
              sm={6}
              xs={{ span: 24, order: 1 }}
              className="text-content vision"
            >
              <img src={Vision} width="100%" height={300} alt="" />
            </Col>
          </Row>
        </Col>

        <Col lg={24}>
          <Row>
            <Col lg={12} md={12} sm={6} xs={24} className="text-content vision">
              <img src={Mission} width="100%" height={300} alt="" />
            </Col>
            <Col lg={12} md={12} sm={6} xs={24} className="text-content vision">
              <CardWrapper className="card_vision">
                <div className="banner-card__body">
                  <h3 style={{ textAlign: 'right' }}>Misi Kami</h3>
                  <p>
                    Mengembangkan sistem yang handal dan ramah pengguna sehingga dapat digunakan
                    baik oleh penjual skala kecil dan besar untuk mendapatkan minyak goreng dengan
                    harga terbaik, dan oleh perusahaan produsen untuk penjualan dan distribusi
                    minyak goreng yang merata dan terekam secara digital.
                  </p>
                </div>
              </CardWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </SectionWrapper>
  )
}

export default SectionVision
