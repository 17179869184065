import React from 'react'
import { Row, Col } from 'antd'
import { CardWrapper, SectionWrapper } from './style'
import Aos from 'aos'

const SectionFlow = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <SectionWrapper>
      <Row>
        <Col lg={24}>
          <Row>
            <Col
              xxl={{ span: 8, order: 1 }}
              xl={{ span: 8, order: 1 }}
              lg={{ span: 8, order: 1 }}
              md={{ span: 24, order: 2 }}
              xs={{ span: 24, order: 2 }}
              className="justify-center "
              data-aos="fade-up"
            >
              <CardWrapper className="card-flow">
                <Row>
                  <Col lg={24} md={24} xs={24}>
                    <div className="banner-card__body">
                      <h5>Produsen</h5>
                      <p>
                        Migoku membantu produsen berkolaborasi untuk meningkatkan penjualan serta
                        mengelola pesanan, stok barang, logistik, dan tagihan.
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardWrapper>
            </Col>
            <Col
              xxl={{ span: 8, order: 2 }}
              xl={{ span: 8, order: 2 }}
              lg={{ span: 8, order: 2 }}
              md={{ span: 24, order: 1 }}
              xs={{ span: 24, order: 1 }}
              className="margin-v justify-center"
              data-aos="fade-up"
            >
              <div className="align-center">
                <img
                  className="flow-img"
                  width={'100%'}
                  src={require(`../../assets/img/migoku-ideas.png`)}
                  alt=""
                />
              </div>
            </Col>
            <Col
              xxl={{ span: 8, order: 3 }}
              xl={{ span: 8, order: 3 }}
              lg={{ span: 8, order: 3 }}
              md={{ span: 24, order: 3 }}
              xs={{ span: 24, order: 3 }}
              className="justify-center"
              data-aos="fade-up"
            >
              <CardWrapper className="card-flow">
                <Row>
                  <Col lg={24} md={24} xs={24}>
                    <div className="banner-card__body">
                      <h5>Pelanggan</h5>
                      <p>
                        Migoku mempermudah pelanggan untuk mendapatkan minyak goreng dari produsen
                        dengan harga terupdate dan terbaik.
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardWrapper>
            </Col>

            <Col
              xxl={{ span: 8, offset: 8, order: 4 }}
              xl={{ span: 8, offset: 8, order: 4 }}
              lg={{ span: 8, offset: 8, order: 4 }}
              sm={{ span: 24, order: 4 }}
              xs={{ span: 24, order: 4 }}
              className="justify-center"
              data-aos="fade-up"
            >
              <CardWrapper className="card-flow">
                <Row>
                  <Col lg={24} md={24} xs={24}>
                    <div className="banner-card__body">
                      <h5>Pemerintah</h5>
                      <p>
                        Migoku membantu pemerintah untuk pemerataan distribusi minyak goreng dan
                        mencegah kelangkaan minyak goreng.
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </SectionWrapper>
  )
}

export default SectionFlow
