export const partners = [
  {
    id: '1',
    image: 'incasi_logo.png',
  },
  {
    id: '2',
    image: 'gunas_logo.png',
  },
  {
    id: '3',
    image: 'incasi_logo.png',
  },
  {
    id: '4',
    image: 'gunas_logo.png',
  },
  {
    id: '5',
    image: 'incasi_logo.png',
  },
  {
    id: '6',
    image: 'gunas_logo.png',
  },
]
