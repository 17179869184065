import { getTranslate } from 'utility/MapperTranslate'

export const listMenu = () => {
  let rows = [
    {
      uid: 'home',
      menu_name: getTranslate('menu.home'),
      menu_path: '/',
      menu_level: 1,
      menu_parent_id: null,
      menu_sort_number: 1,
      children: null,
    },
    {
      uid: 'company',
      menu_name: 'Perusahaan',
      menu_path: '#',
      menu_level: 1,
      menu_parent_id: null,
      menu_sort_number: 3,
      children: [
        {
          uid: 'about',
          menu_name: getTranslate('menu.about_us'),
          menu_path: '/about',
          menu_level: 1,
          menu_parent_id: null,
          menu_sort_number: 2,
          children: null,
        },
        {
          uid: 't&c',
          menu_name: getTranslate('menu.toc'),
          menu_path: '/terms-and-conditions',
          menu_level: 2,
          menu_parent_id: 'company',
          menu_sort_number: 2,
          children: null,
        },
        {
          uid: 'privacy-policy',
          menu_name: getTranslate('menu.privacy_policy'),
          menu_path: '/privacy-policy',
          menu_level: 2,
          menu_parent_id: 'company',
          menu_sort_number: 3,
          children: null,
        },
      ],
    },
    {
      uid: 'contact',
      menu_name: getTranslate('menu.contact_us'),
      menu_path: '/contact-us',
      menu_level: 1,
      menu_parent_id: null,
      menu_sort_number: 3,
      children: null,
    },
  ]

  return rows
}
