import React from 'react'
import { Row, Col } from 'antd'
import { ButtonRounded, Div } from './style'
import { ReactComponent as Android } from '../../assets/img/android-logo.svg'
import { getTranslate } from 'utility/MapperTranslate'
import Aos from 'aos'

const SectionPromotion = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <Div data-aos="fade-down" data-aos-easing="ease" data-aos-duration="2000">
      <div className="content-wrapper">
        <Row>
          <Col lg={12} md={14} className="content-text">
            <div>
              <h2>Download Aplikasi</h2>
              <p>Download dan install aplikasi Migoku dan terhubung dengan produsen terbaik!</p>
              <Row>
                <Col lg={8} md={6}>
                  <ButtonRounded
                    className="btn-normal"
                    onClick={() =>
                      window.open(
                        `https://play.google.com/store/apps/details?id=com.migoku.customer`,
                      )
                    }
                  >
                    <Android className="icon" />
                    {getTranslate('button.play_store')}
                  </ButtonRounded>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={10} md={10} xs={24} className="content-image">
            <img src={require(`../../assets/img/showcase-app-3.png`)} width={410} alt="" />
          </Col>
        </Row>
      </div>
    </Div>
  )
}

export default SectionPromotion
