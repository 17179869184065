import React, { useState, useEffect, useCallback } from 'react'
import { HeaderMenu, MobileMenu } from './style'
import { Row, Col } from 'antd'
import { Link, NavLink } from 'react-router-dom'
import { listMenu } from 'constant/menu/Menu'
import MenuInfo from './MenuInfo'
import FeatherIcon from 'feather-icons-react'
import logoWhite from '../../assets/icon/icon-logoWhite.svg'

const Header = () => {
  const [collapse, setCollapse] = useState('true')
  const [showHide, setShowHide] = useState(false)
  const [pagePosition, setPagePosition] = useState(0)
  const location = window.location.pathname
  // const [status] = useState(queryParams.get('status'))

  const toggleCollapsed = useCallback(() => {
    setCollapse((prevCollapse) => (prevCollapse === 'true' ? 'false' : 'true'))
  }, [])

  const toggleCollapsedMobile = useCallback(() => {
    setShowHide((showHide) => !showHide)
  }, [setShowHide])

  const handlescroll = () => {
    const position = window.pageYOffset
    setPagePosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handlescroll)

    return () => {
      window.addEventListener('scroll', handlescroll)
    }
  }, [])

  return (
    <>
      <HeaderMenu
        pageposition={pagePosition}
        collapse={collapse}
        className={location === '/payment/confirmation' ? 'colored-header' : null}
      >
        <Row>
          <Col lg={4} md={4} sm={6} xs={12} className="align-center-v">
            <Link to="/">
              <img src={logoWhite} style={{ width: 110, height: 35 }} alt="Migoku" />
            </Link>
          </Col>
          <Col lg={20} md={20} sm={0} xs={0}>
            <MenuInfo />
          </Col>

          <Col md={0} sm={18} xs={12}>
            {window.innerWidth <= 768 ? (
              <div className="mobile-action">
                <Link to="#" onClick={toggleCollapsed}>
                  <FeatherIcon icon="menu" />
                </Link>
              </div>
            ) : null}
          </Col>
        </Row>
      </HeaderMenu>

      <div className="mobile-header">
        <Row>
          <Col md={0} sm={24} xs={24}>
            <div className="small-screen-header">
              <MobileMenu collapse={collapse}>
                <Row>
                  <Col md={0} sm={24} xs={24}>
                    <div className="navMenu-mobile">
                      <ul>
                        {listMenu()?.map((row, key) => {
                          return row.menu_level === 1 ? (
                            <li key={key} className={!row.children ? '' : 'has-subMenu'}>
                              {!row.children ? (
                                <Link onClick={toggleCollapsed} to={row.menu_path}>
                                  {row.menu_name}
                                </Link>
                              ) : (
                                <Link to="#" className="parent" onClick={toggleCollapsedMobile}>
                                  {row.menu_name}
                                </Link>
                              )}
                              {row.children?.length && (
                                <ul className={`subMenu ${showHide ? 'active' : ''}`}>
                                  {row.children.map((children, index) => {
                                    return (
                                      <li key={index}>
                                        <NavLink onClick={toggleCollapsed} to={children.menu_path}>
                                          {children.menu_name}
                                        </NavLink>
                                      </li>
                                    )
                                  })}
                                </ul>
                              )}
                            </li>
                          ) : null
                        })}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </MobileMenu>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Header
