import React from 'react'
import { Row, Col } from 'antd'
import { SectionWrapper } from './style'
import Aos from 'aos'

const SectionAbout = () => {
  Aos.init({
    duration: 1200,
  })

  return (
    <SectionWrapper>
      <Row>
        <Col lg={12} md={12} sm={6} xs={24} data-aos="fade-down">
          <Row>
            <img src={require(`../../assets/img/about-image.png`)} alt="" className="box" />
          </Row>
        </Col>
        <Col lg={12} md={12} sm={6} xs={24} className="text-content" data-aos="fade-right">
          <Row>
            <h3>Migoku</h3>
            <p>
              Sistem penjualan digital yang dirancang untuk memfasilitasi transaksi minyak goreng
              serta membantu pemerintah dalam pemerataan distribusi dan mencegah kelangkaan minyak
              goreng terjadi di Indonesia.
            </p>
          </Row>
        </Col>
      </Row>
    </SectionWrapper>
  )
}

export default SectionAbout
