import React from 'react'
import { Col, Row, Space } from 'antd'
import { CardWrapper, SectionWrapper } from './style'
import Clock from '../../assets/icon/icon-clock.svg'
import Phone from '../../assets/icon/icon-phone.svg'
import Scan from '../../assets/icon/icon-scan.svg'
import Hand from '../../assets/icon/icon-hand.svg'
import Calendar from '../../assets/icon/icon-calendar.svg'
import Chart from '../../assets/icon/icon-chart.svg'
import Aos from 'aos'

const SectionFeatures = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <SectionWrapper
      style={{ backgroundColor: 'rgba(254, 242, 238, 1)' }}
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
    >
      <Row>
        <Col xl={12} lg={24} style={{ marginBottom: '20px' }}>
          <h3 style={{ color: 'var(--font-color)' }}>
            Kenapa Harus <span>Migoku</span>
          </h3>
        </Col>
        <Col lg={24}>
          <Row>
            <Col xxl={8} xl={8} lg={8} md={24} className="justify-center">
              <Space direction="vertical" size={50}>
                <CardWrapper data-aos="zoom-in-left" data-aos-duration="2000" className="card">
                  <Row>
                    <Col lg={6} md={4} xs={24}>
                      <div className="banner-card__figure">
                        <img src={Clock} style={{ width: 50 }} alt={'Clock'} />
                      </div>
                    </Col>
                    <Col lg={18} md={20} xs={24}>
                      <div className="banner-card__body flow">
                        <h5>Pendaftaran Mudah dan Tidak Dipungut Biaya</h5>
                        <p>Pendaftaran online yang ringkas dan tidak berbelit-belit.</p>
                      </div>
                    </Col>
                  </Row>
                </CardWrapper>
                <CardWrapper data-aos="zoom-in-left" data-aos-duration="2000" className="card">
                  <Row>
                    <Col lg={6} md={4} xs={24}>
                      <div className="banner-card__figure">
                        <img src={Phone} style={{ width: 50 }} alt={'Phone'} />
                      </div>
                    </Col>
                    <Col lg={18} md={20} xs={24}>
                      <div className="banner-card__body flow">
                        <h5>Transaksi Mudah, Cepat, dan Terekam Secara Digital</h5>
                        <p>History pesanan, invoice, dan dokumen-dokumen yang bisa diunduh.</p>
                      </div>
                    </Col>
                  </Row>
                </CardWrapper>
                <CardWrapper data-aos="zoom-in-left" data-aos-duration="2000" className="card">
                  <Row>
                    <Col lg={6} md={4} xs={24}>
                      <div className="banner-card__figure">
                        <img src={Scan} style={{ width: 50 }} alt={'Scan'} />
                      </div>
                    </Col>
                    <Col lg={18} md={20} xs={24}>
                      <div className="banner-card__body flow" style={{ height: 100 }}>
                        <h5>Dapatkan Harga Terbaru</h5>
                        <p>Dapatkan update harga dari produsen secara real-time.</p>
                      </div>
                    </Col>
                  </Row>
                </CardWrapper>
              </Space>
            </Col>
            <Col xxl={8} xl={8} lg={8} md={24} xs={24} className="margin-v justify-center">
              <div className="align-center small-image card-phone" style={{ height: '100%' }}>
                <img
                  src={require(`../../assets/img/showcase-app-2.png`)}
                  style={{ width: '380px' }}
                  alt=""
                />
              </div>
            </Col>
            <Col xxl={8} xl={8} lg={8} md={24} className="justify-center">
              <Space direction="vertical" size={50}>
                <CardWrapper
                  className="card card-mobile"
                  data-aos="zoom-in-right"
                  data-aos-duration="2000"
                >
                  <Row>
                    <Col lg={6} md={4} xs={24}>
                      <div className="banner-card__figure">
                        <img src={Hand} style={{ width: 50 }} alt={'Hand'} />
                      </div>
                    </Col>
                    <Col lg={18} md={20} xs={24}>
                      <div className="banner-card__body flow" style={{ height: 100 }}>
                        <h5>Nikmati Diskon Produsen</h5>
                        <p>Jangan lewatkan promo dan diskon produsen, ya!</p>
                      </div>
                    </Col>
                  </Row>
                </CardWrapper>
                <CardWrapper data-aos="zoom-in-right" data-aos-duration="2000" className="card">
                  <Row>
                    <Col lg={6} md={4} xs={24}>
                      <div className="banner-card__figure">
                        <img src={Calendar} style={{ width: 50 }} alt={'Calendar'} />
                      </div>
                    </Col>
                    <Col lg={18} md={20} xs={24}>
                      <div className="banner-card__body flow" style={{ height: 100 }}>
                        <h5>Jadwal Fleksibel</h5>
                        <p>Atur jadwal penjemputan dan pengiriman pesanan anda!</p>
                      </div>
                    </Col>
                  </Row>
                </CardWrapper>
                <CardWrapper data-aos="zoom-in-right" data-aos-duration="2000" className="card">
                  <Row>
                    <Col lg={6} md={4} xs={24}>
                      <div className="banner-card__figure">
                        <img src={Chart} style={{ width: 50 }} alt={'Chart'} />
                      </div>
                    </Col>
                    <Col lg={18} md={20} xs={24}>
                      <div className="banner-card__body flow" style={{ height: 100 }}>
                        <h5>Dapatkan Insight dan Trend</h5>
                        <p>Dapatkan insight seputar penjualan dan tim anda.</p>
                      </div>
                    </Col>
                  </Row>
                </CardWrapper>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </SectionWrapper>
  )
}

export default SectionFeatures
