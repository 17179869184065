import React from 'react'
import {
  Home,
  PageAboutUs,
  PageNotFound,
  PagePrivacyPolicy,
  PageToc,
  PageContact,
  PagePaymentConfirmation,
} from 'containers/Main'

import i18n from 'utility/i18n'
function getTitle(key) {
  let lang = i18n.t(key)
  if (lang === undefined) {
    lang = 'Sales & Distribution System'
  }
  return lang
}
const RoutePath = [
  {
    path: '/',
    title: getTitle('menu.home'),
    element: <Home />,
    auth: false,
  },
  {
    path: '/about',
    title: getTitle('menu.about_us'),
    element: <PageAboutUs />,
    auth: false,
  },
  {
    path: '/terms-and-conditions',
    title: getTitle('menu.toc'),
    element: <PageToc />,
    auth: false,
  },
  {
    path: '/privacy-policy',
    title: getTitle('menu.privacy_policy'),
    element: <PagePrivacyPolicy />,
    auth: false,
  },
  {
    path: '/contact-us',
    title: getTitle('menu.contact_us'),
    element: <PageContact />,
    auth: false,
  },
  {
    path: '/payment/confirmation',
    title: getTitle('menu.payment_confirmation'),
    element: <PagePaymentConfirmation />,
    auth: false,
  },
  { path: '*', title: '404', element: <PageNotFound />, auth: false },
]

export default RoutePath
