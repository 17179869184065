import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { listMenu } from 'constant/menu/Menu'
import { MenuWrapper } from './style'

const MenuInfo = () => {
  return (
    <MenuWrapper>
      <div className="navMenu">
        <ul>
          {listMenu()?.map((row, key) => {
            return row.menu_level === 1 ? (
              <li key={key} className={!row.children ? '' : 'has-subMenu'}>
                {!row.children ? (
                  <Link to={row.menu_path}>{row.menu_name}</Link>
                ) : (
                  <Link to="#" className="parent">
                    {row.menu_name}
                  </Link>
                )}
                {row.children?.length && (
                  <ul className="subMenu">
                    {row.children.map((children, index) => {
                      return (
                        <li key={index}>
                          <NavLink to={children.menu_path}>{children.menu_name}</NavLink>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </li>
            ) : null
          })}
        </ul>
      </div>
    </MenuWrapper>
  )
}

export default MenuInfo
