import React from 'react'
import { Row, Col, Space } from 'antd'
import { order } from 'constant/workflow/Workflow'
import { Cards, SectionWrapper } from './style'
import Aos from 'aos'

const SectionWorkflow = () => {
  Aos.init({
    duration: 1200,
  })
  return (
    <SectionWrapper
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="3000"
    >
      <Row>
        <Col lg={24} style={{ width: '100%' }}>
          <div className="justify-center">
            <h3>
              Langkah Untuk Order di <span>Migoku</span>
            </h3>
          </div>
        </Col>
        <Col lg={24} xs={24}>
          <Space direction="horizontal" size="large" className="justify-center">
            {order.map((item, index) => {
              return (
                <Col xl={6} lg={6} md={6} xs={0} key={index}>
                  <div className="animationBorder" />
                  <Cards
                    hoverable
                    cover={<img src={require(`../../assets/img/${item.order_image}`)} alt="" />}
                  >
                    <p>{item.order_description}</p>
                  </Cards>
                </Col>
              )
            })}
          </Space>

          <div className="mobile-cards">
            <Space direction="vertical" size="large" className="justify-center">
              {order.map((item, index) => {
                return (
                  <Col xl={0} lg={0} md={0} sm={6} xs={24} key={index}>
                    <Cards
                      hoverable
                      cover={<img src={require(`../../assets/img/${item.order_image}`)} alt="" />}
                    >
                      <p>{item.order_description}</p>
                    </Cards>
                  </Col>
                )
              })}
            </Space>
          </div>
        </Col>
      </Row>
    </SectionWrapper>
  )
}

export default SectionWorkflow
