import React from 'react'
import { Row, Col } from 'antd'
import { Banners, ButtonRounded } from './style'
import { ReactComponent as WavePattern } from '../../assets/img/wave-pattern.svg'
import { getTranslate } from 'utility/MapperTranslate'
import PropTypes from 'prop-types'

const SectionHero = (props) => {
  return props.option === 'hero' ? (
    <Banners>
      <WavePattern className="wave" />
      <div className="banner-wrapper">
        <Row gutter={25}>
          <Col xl={14} lg={16} md={16} xs={14} className="banner-text">
            <h1>Pengalaman baru berbelanja minyak secara digital</h1>
            <p>Tinggal klik, pesan, dan tunggu pesanan sampai kepada anda.</p>
            <Row>
              <Col xl={9} lg={9} md={11}>
                <ButtonRounded
                  className="btn-example"
                  onClick={() =>
                    window.open(`https://play.google.com/store/apps/details?id=com.migoku.customer`)
                  }
                >
                  <span>{getTranslate('button.download_now')}</span>
                </ButtonRounded>
              </Col>
              <Col xl={9} lg={9} md={11}>
                <ButtonRounded
                  className="btn-example"
                  onClick={() => (window.location.href = '/contact-us')}
                >
                  <span>{getTranslate('button.contact_us')}</span>
                </ButtonRounded>
              </Col>
            </Row>
          </Col>
          <Col xl={10} lg={8} md={8} xs={10} className="banner-image">
            <img src={require(`../../assets/img/showcase-app-1.png`)} alt="" />
          </Col>
        </Row>
      </div>
    </Banners>
  ) : (
    <Banners className="banner-size">
      <WavePattern className="wave" />
      <div className="banner-wrapper" style={{ justifyContent: 'center' }}>
        <Row>
          <Col span={24} className="banner-text">
            <h1>{getTranslate(`menu.${props.page}`)}</h1>
          </Col>
        </Row>
      </div>
    </Banners>
  )
}

SectionHero.propTypes = {
  option: PropTypes.oneOf(['hero', 'page_title']),
  page: PropTypes.oneOf(['about_us', 'toc', 'privacy_policy', 'contact_us']),
}

export default SectionHero
