import {
  Loading,
  SectionHero,
  SectionAbout,
  SectionFeatures,
  SectionWorkflow,
  SectionPromotion,
  SectionPartners,
  SectionContact,
  SectionFlow,
} from 'components/Main'
import React, { useState } from 'react'

const Home = () => {
  const [loading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, [process.env.REACT_APP_Loading])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <>
          <SectionHero option="hero" />
          <SectionAbout />
          <SectionFlow />
          <SectionFeatures />
          <SectionWorkflow />
          <SectionPromotion />
          <SectionPartners />
          <SectionContact contact={'modal'} />
        </>
      )}
    </>
  )
}
export default Home
