import Styled from 'styled-components'
import { Button, Card, Collapse, Modal } from 'antd'
import BackgroundBanner from '../../assets/img/background-hero.png'
import BackgroundBanner2 from '../../assets/img/people-buying-oil.png'

const Banners = Styled.div`
    position: relative;
    background: #9c6060;
    background: url(${BackgroundBanner});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    width: 100%;
    height: 100vh;

    &.banner-size {
        height: 75vh;
        justify-content: center;
        .banner-wrapper {
            height: 75vh;
        }
    }

    .wave {
        position: absolute;
        z-index: 2;
        margin: auto;
        bottom: -5px;
        width: 100%;
    }

    .banner-wrapper {
        display: flex;
        align-items: center;
        padding: 50px 70px;
        height: 100vh;
        
        .banner-text {
            color: var(--white-color);
            margin: auto;

            h1, h2 {
                color: var(--white-color);
                font-weight: 700;
                line-height: 1.2;
            }

            p {
                color: var(--white-color);
                font-size: 20px;
                padding-right: 60px;
            }

        }

        .banner-image {
            display: flex;
            justify-content: center;
            margin: auto;
            padding: 0 50px;

            img {
                width: 320px;
            }
        }

    }

    @media only screen and (max-width: 992px) {
        .banner-wrapper {
            padding: 30px 50px;


            .banner-image {

                img {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .wave {
            bottom: 0;
        }

        .banner-wrapper {
            padding: 30px 25px;
            height: 100vh;

            .banner-text {

                p {
                    padding: 0;
                }
            }

            .banner-image {

                img {
                    width: 100%;
                }
            }
        }
    }
`

const SectionWrapper = Styled.div`
    position: relative;
    z-index: 1;
    padding: 70px;
    overflow: hidden;

    &.payment {
        margin-top: 12vh;
        height: 80vh;
        align-content: center;
        display: grid;
    }

    a {
        color: var(--orange-color);
        &.ant-typography:hover{
            color: var(--orange-color);
        }
    }

    {
        .ant-space-item:hover {
            .animationBorder{
                position: relative;
                z-index: 0;
                width: 244px;
                height: 330px;
                border-radius: 10px;
                overflow: hidden;
                padding: 2rem;
                margin-bottom: -327px;
                margin-left: -2px;
            
                &::before {
                    content: '';
                    position: absolute;
                    z-index: -2;
                    left: -50%;
                    top: -50%;
                    width: 200%;
                    height: 200%;
                    background-color: white;
                    background-repeat: no-repeat;
                    background-size: 50% 50%,50% 50%;
                    background-position: 0 0,100% 0,100% 100%,0 100%;
                    background-image: linear-gradient(#F2B8A3,#F25D27);
                    animation: rotate 4s linear infinite;
                }
            
            @keyframes rotate {
                100% {
                    transform: rotate(1turn);
                }
            }
            
        }
    }

    .location {
        border: none;
        border-radius: 6px;
        box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    }
    
    .box {
        width: 100%; 
        border-radius: 20px;
        box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 20px 40px rgba(0, 0, 0, 0.12)
      ;
    }

    .wave {
        position: absolute;
        left: 0;
        bottom: -30px;
    }

    .small-image {
        padding: 10px;
    }

    .flow-img {
        width: 380px;
    }

    h2 {
        color: var(--white-color) !important;
        font-weight: 700;
    }

    .text-content {
        padding: 0 80px;
        margin: auto;

        &.vision{
            h3{
                color: var(--white-color) !important;
            }
            p{
                font: var(--addition-font) !important;
                color: var(--pastel-orange-color) !important;
            }
        }

        h3 {
            color: var(--orange-color) !important;
        }

        p {
            color: var(--font-color);
            text-align: justify;
            line-height: 1.5;
        }
    }

    .swiper {
        width: 100%;
        height: 150px;

        .slide-container {
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                height: 120px;
                display: flex;
                justify-content: center;
                object-fit: scale-down;
            }
        }

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .swiper-pagination-bullet-active {
            background: var(--orange-color);
        }
    }

    .state-illustration {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        
        img {
            width: 30%;
        }
    }

    .state-text-wrapper {
        margin-top: 10px;
        text-align: center;

        h3 {
            font-size: 20px;
            color: var(--orange-color);
        }

        p {
            font-size: 16px;
        }
    }

    @media only screen and (max-width: 1200px) {
        {
            .ant-space-item:hover {
                .animationBorder{
                    width: 216px;
                    height: 300px;
                    margin-bottom: -298px;
                    margin-left: -3px;
                }
            }
        }

        pre {
            h4{
                font-size: 16px;
            }
        }

        .text-content {
            padding: 0 50px;
        }

        .small-image {
            padding: 30px;

            img {
                width: 240px !important;
            }
        }

        .flow-img {
            width: 250px;
        }
    }

    @media only screen and (width: 1024px) {
        .card-phone {
            margin-top: 300px;
        }
    }

    @media only screen and (max-width: 992px) {
        padding: 50px;

        &.payment {
            margin-top: 8vh;
            height: 88vh;
        }

        {
            .ant-space-item:hover {
                .animationBorder{
                    width: 156px;
                    height: 260px;
                    margin-bottom: -257px;
                    margin-left: -3px;
                }
            }
        }

        .small-image {
            padding: 30px;

            img {
                width: 340px !important;
            }
        }
        
        .text-content {
            margin: 0;
            padding: 0 30px;
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 50px 25px;

        &.payment {
            margin-top: 12vh;
            height: 72vh;
        }

        pre {
            h4{
                font-size: 14px;
            }
        }
        
        .text-content {
            margin: 30px 0;
            padding: 0 20px;
        }

        .small-image {
            padding: 30px;

            img {
                width: 260px !Important;
            }
        }
        .flow-img {
            width: 280px;
        }
    }
    
`

const CardWrapper = Styled.div`
    background: linear-gradient(98.6deg, #F25D27 0%, #FA9F32 100%);
    border-radius: 8px;
    padding: 10px;

    &.card_vision{
        &:hover{
            color: red;
            background: var(--white-color);
            
            .banner-card__body{
                h3 {
                    color: var(--orange-color) !important;
                }
                P {
                    color: var(--orange-color) !important;
                }
            }
        }
    }

    .banner-card__figure {
        background-color: var(--white-color);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: var(--orange-color);
    }

    .banner-card__body {
        padding: 0 15px;
        font-family: var(--body-font);
        color: var(--white-color);
        
        h5 {
            font-size: 16px;
            color: var(--white-color);
        }

        p {
            font-size: 14px;
            line-height: 1.4;
            color: var(--white-color);
        }
    }

    @media only screen and (max-width: 1200px) {
        .banner-card__body {
            
            h5 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media only screen and (width: 1024px) {
        &.card {
            width: 300px !important;
            height: 160px !important;
            {
                .banner-card__figure {
                    height: 140px;
                }
            }
        }

        .banner-card__body {
            &.flow {
                h5 {
                    font-size: 18px !important;
                }

                p {
                    font-size: 14px !important;
                }
            }
        }
        
        &.card-flow {
            height: 170px !important;
        }

        &.card-mobile{
            width: auto !important;
        }
    }

    @media only screen and (min-width: 576px) and (max-width: 1024px) {
        &.card-flow {
            margin-top: 10px;
            height: auto;
        }
        
        &.card-mobile{
            width: 600px;
        }
    }
    
    @media only screen and (min-width: 320px) and (max-width: 992px) {
        &.card-flow {
            margin-top: 10px;
            height: auto;
        }

        .banner-card__figure {
            padding: 20px 0;
            
            svg {
                color: var(--orange-color) !important;
            }
        }

        .banner-card__body {
            margin: 15px 0;

            h5 {
                font-size: 20px;
            }

            p {
                font-size: 14px !important;
            }
        }
    }
`

const Cards = Styled(Card)`
    background-color: var(--card-color);
    border-radius: 8px;
    width: 240px;

    p {
        font-family: var(--body-font);
        font-size: 16px;
        font-weight: 600;
        color: var(--font-color);
        text-align: center;
    }

    @media only screen and (max-width: 1200px) {
        width: 210px;
    }

    @media only screen and (max-width: 992px) {
        width: 150px;
    }
    
    @media only screen and (max-width: 767px) {
        width: 250px;
    }
`

const Div = Styled.div`
    position: relative;
    background: url(${BackgroundBanner2});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    width: 100%;

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #F9971B 0%, #F25D27 100%);
        opacity: 0.8;
        transform: rotate(0.09deg);
    }

    .content-wrapper {
        position: relative;
        
        .content-text {
            padding: 0 40px;
            margin: auto;

            h2 {
                font-weight: 700;
                color: var(--white-color);
            }

            p {
                color: var(--white-color);
            }
        }

        .content-image {
            overflow: hidden;
            bottom: 0;
            padding: 30px 30px 0;
            
        }
    }

    @media only screen and (max-width: 1200px) {
        .content-wrapper {
            
            .content-image {
                img {
                    width: 300px;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .content-wrapper {
            
            .content-text {
                padding: 50px 25px;
            }

            .content-image {
                display: flex;
                justify-content: center;
                img {
                    width: 350px;
                }
            }
        }
    }
`

const ButtonRounded = Styled(Button)`
    background-color: var(--white-color);
    color: var(--orange-color);
    border-radius: 50px;
    font-family: var(--body-font);
    font-size: 20px;
    font-weight: 600;
    padding: 14px 20px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-top: 16px;
    height: unset !important;

    span {
        margin-left: 10px;
    }

    &.btn-normal {
        border-radius: 10px !important;
    }

    &.btn-example {
        span {
            margin: 0;
        }
    }

    .icon g {
        fill: var(--orange-color);
        transition: all .2s ease-in-out;
    }
    
    .feather-phone {
        fill: var(--orange-color);
        stroke: none;
        transition: all .2s ease-in-out;
    }
    
    &:hover, &:focus {
        background-color: var(--orange-color);
        color: var(--white-color);
        border: 1px solid var(--white-color);
        
        .icon g {
            fill: var(--white-color);
            background-color: var(--white-color);
            transition: all .2s ease-in-out;
        }
        
        .feather-phone {
            fill: var(--white-color);
            transition: all .2s ease-in-out;
        }

    }

    @media only screen and (max-width: 1200px) {
        font-size: 16px;
    }

    @media only screen and (max-width: 992px) {
        padding: 8px 15px;
    }

    @media only screen and (max-width: 567px) {
        &.btn-example {
            padding: 4px 10px;

            span {
                font-size: 14px;
            }
        }
    }
`

const Modals = Styled(Modal)`
    .ant-typography {
        &.h2{
            strong{
                font-size: 30px;
            }
        }
        &.header{
            font-size: 24px;
            margin-bottom: 10px;
        }
        &.h5{
            font-size: 20px;
            text-align: justify;
        }
    }
    
    .ant-modal-content {
        border-radius: 8px;
        overflow: hidden;

        .ant-modal-close{
            color: var(--pastel-orange-color);
            height: 70px;
            &:hover{
                color: #fff;
            }
        }
    }
    
    .ant-modal-header{
        background: linear-gradient(#F96B1B 0%, #F25D27 100%);
        border-bottom: 0;
        height: 70px;
    }
    
    .ant-modal-footer {
        border: none;
    }

    @media only screen and (min-width: 1200px) {
        width: 50% !important;
    }
    
    @media only screen and (max-width: 567px) {
        .ant-typography {
            &.h2{
                strong{
                    font-size: 26px;
                }
            }
            &.h3{
                font-size: 20px;                
            }
            &.h5{
                font-size: 16px;
                text-align: justify;
            }
        }
    }
    `

const StyledPanel = Styled(Collapse.Panel)`
    && {
        border: none;
        border-radius: 0px;
        background-image: linear-gradient(90deg, #F96B1B 30%, #F25D27 60%);
        box-shadow: none;
        font-size: 16px;
    }

.ant-collapse-header-text {
    font-weight: 600;
    color: var(--white-color);
}
.ant-collapse-content {
    background: #fff;
}

@media only screen and (max-width: 567px) {
    .ant-collapse-header-text {
        font-size: 12px;
    }
    .ant-collapse-content {
        font-size: 12px;
    }
}
`

export { Banners, ButtonRounded, SectionWrapper, CardWrapper, Cards, Div, Modals, StyledPanel }
