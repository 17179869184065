export const order = [
  {
    id: '1',
    order_image: 'order-ride.png',
    order_description: '1. Masuk Ke Aplikasi',
  },
  {
    id: '2',
    order_image: 'add-to-cart.png',
    order_description: '2. Buat Pesanan',
  },
  {
    id: '3',
    order_image: 'destination.png',
    order_description: '3. Barang Dikirim',
  },
  {
    id: '4',
    order_image: 'feedback.png',
    order_description: '4. Barang Diterima',
  },
]
