import React from 'react'
import { Row, Col } from 'antd'
import { StyleFooter } from './style'
const Footer = () => {
  return (
    <StyleFooter>
      <Row>
        <Col lg={24} md={24} xs={24} className="text-center padding-v mobile-padding-v">
          <Row>
            <Col lg={12} md={12} xs={24}>
              <p>
                Copyright &copy; {new Date().getFullYear()} <span>Migoku.co.id. </span>All rights
                reserved.
              </p>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <p>PT. Widea Dagang Nusantara</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyleFooter>
  )
}

export default Footer
