import React, { useState } from 'react'
import { Loading, SectionArticle, SectionHero } from 'components/Main'

const PageToc = () => {
  const [loading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, [process.env.REACT_APP_Loading])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <>
          <SectionHero option="page_title" page="toc" />
          <SectionArticle />
        </>
      )}
    </>
  )
}

export default PageToc
